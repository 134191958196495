import { NgModule } from '@angular/core';
import { AdminLayoutComponent } from './admin/admin.component';
import { AuthLayoutComponent } from './auth/auth.component';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { NotificationComponent } from '../shared/components/notification/notification.component';
import { MatTooltipModule } from '@angular/material/tooltip';
@NgModule({
  declarations: [AdminLayoutComponent, AuthLayoutComponent, HeaderComponent, SideNavComponent, NotificationComponent],
  imports: [SharedModule, MatSidenavModule, MatToolbarModule, MatIconModule, MatListModule, MatButtonModule, MatMenuModule, MatBadgeModule, MatTooltipModule],
})
export class LayoutModule {
}
