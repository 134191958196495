import { Component, isDevMode } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { makeid } from '../../../shared';
import { SettingService, TokenService } from '../../../core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../shared/services/http.service';
import { UrlService } from '../../../shared/services/url.service';
import { StoreService } from 'src/app/shared/services/store.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  public showPassword = false;
  public loginForm: FormGroup;
  public viewHelper = {
    loggingIn: false
  };

  get username() {
    return this.loginForm.get('username');
  }

  get password() {
    return this.loginForm.get('password');
  }
  constructor(
    private fb: FormBuilder,
    private settings: SettingService,
    private router: Router,
    private httpService: HttpService,
    private toster: ToastrService,
    private token: TokenService,
    private storeService: StoreService
  ) {
    this.loginForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
    console.log("isDevMode", isDevMode);

    if (isDevMode()) {
      // this.loginForm.controls.username.setValue('admin');
      // this.loginForm.controls.password.setValue('p@ssword');
    }
  }

  public login(): void {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
      this.viewHelper.loggingIn = true;
      this.loginForm.disable();
      this.httpService.postData(UrlService.LOGIN_URL, this.loginForm.value)
        .subscribe(res => {
          this.viewHelper.loggingIn = false;
          this.loginForm.enable();
          this.onLoginSuccess(res);
        }, error => {
          this.toster.error(error?.error?.data || 'Something went wrong');
          this.loginForm.enable();
          this.viewHelper.loggingIn = false;
        });
    } else {
      console.log('error');
    }
  }

  private onLoginSuccess(response): void {
    const { token, uid, username, userRole, userId, company } = {
      token: response.token,
      uid: makeid(25),
      username: response?.data?.username,
      userRole: response?.data?.userRole,
      userId: response?.data?._id,
      company: response?.data?.company
    };
    // Set user info
    this.settings.setUser({
      ...response?.data
    });
    // Set token info
    this.token.set({ token, uid, username, userId, company });
    // Regain the initial data
    let url = this.token.referrer!.url || '/';
    if (url.includes('/auth')) {
      url = '/';
    }
    this.router.navigateByUrl(url);
  }


}
