import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { GET_COURSE_LIST_SERVER, GetListOfCourse, SuccessCourseComplete } from './course.action';
import { map, switchMap } from 'rxjs/operators';
import { LazyDataLoaderService } from 'src/app/shared/services/lazy-data-loader.service';

@Injectable()
export class courseEffects {

    constructor(private lazy: LazyDataLoaderService, private actions$: Actions) {
        console.log('[EFFECT] : Course');
    }

    @Effect()
    public GetListOfCourse$ = this.actions$.pipe(
        ofType(GET_COURSE_LIST_SERVER),
        switchMap((action: GetListOfCourse) => {
            return this.lazy.getCourseList(action.payLoad).pipe(
                map(
                    color => new SuccessCourseComplete(color),
                    errors => console.log(errors)
                )
            );
        })
    );
}
