<mat-form-field class="w-100">
    <mat-select (selectionChange)="onSelectionChange()" [formControl]="uniMultiCtrl" [placeholder]="placeholder"
        [multiple]="true" #multiSelect>
        <mat-option>
            <ngx-mat-select-search noEntriesFoundLabel="'no matching course found'" placeholderLabel="Search University"
                [formControl]="uniMultiFilterCtrl">
            </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let data of filteredUniMulti | async" [value]="data[perferredSelector]">
            {{data.name}}
        </mat-option>
    </mat-select>
</mat-form-field>