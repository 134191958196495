import { Action } from '@ngrx/store';

export const GET_USER_LIST_SERVER = 'GET_USER_LIST_SERVER';
export const USER_LIST_SERVER = 'USER_LIST_SERVER';
// export const TIME_HOP_COMPLETED = 'Time hop completed';

export class GetListOfUser implements Action {
  readonly type: string = GET_USER_LIST_SERVER;

  constructor(public payLoad: any) {
    console.log(`[ACTION] ${GET_USER_LIST_SERVER}`);
  }
}
// TIME_HOP_COMPLETED
export class SuccessUserComplete implements Action {
  readonly type: string = USER_LIST_SERVER;

  constructor(public payLoad: any) {
    console.log(`[ACTION] ${USER_LIST_SERVER}`);
  }
}

export type UserAction = GetListOfUser | SuccessUserComplete;
