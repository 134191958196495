import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

import { catchError } from 'rxjs/internal/operators';
import { TokenService } from '../';
import { environment } from '../../../environments/environment';

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {

  constructor(private router: Router,
    private token: TokenService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Add server host
    const url = environment.SERVER_ORIGIN + req.url;

    // Only intercept API url
    /*if (!url.includes('/api/')) {
      return next.handle(req);
    } */
    
    // debugger
    const withCredentialsExcludedApis = ['/login'];
    // if (!url.includes('/api/') || url.includes('/signup') || url.includes('/signin')) {
    //   return next.handle(req);
    // }
    // All APIs need JWT authorization
    // return next.handle(req);
    const headers = {
      Accept: 'application/json',
      Authorization: `${this.token.get().token}`,
      userId: `${this.token.get().userId}`,
    };

    const newReq = req.clone({ url, setHeaders: headers, withCredentials: false });

    return next.handle(newReq)
      .pipe(catchError((err: HttpErrorResponse) => this.handleErrorReq(err)));
  }

  private goto(url: string) {
    setTimeout(() => this.router.navigateByUrl(url));
  }

  private handleOkReq(event: HttpEvent<any>): Observable<any> {
    if (event instanceof HttpResponse) {
      const body: any = event.body;
      // failure: { code: **, msg: 'failure' }
      // success: { code: 0,  msg: 'success', data: {} }
      if (body && body.code !== 0) {
        if (body.msg && body.msg !== '') {
          console.log('Error', body);
        }
        return throwError([]);
      } else {
        return of(event);
      }
    }
    // Pass down event if everything is OK
    return of(event);
  }

  private handleErrorReq(error: HttpErrorResponse): Observable<never> {
    switch (error.status) {
      case 401:
        this.goto(`/auth/login`);
        break;
      // case 403:
      //   // case 404:
      //   // case 500:
      //   this.goto(`/sessions/${error.status}`);
      //   break;
      default:
        if (error instanceof HttpErrorResponse) {
          // console.error('ERROR', error);
          if (error.status) {
            console.log('error', error);
          }
        }
        break;
    }
    console.log(error, 'error');
    return throwError(error);
  }
}
