import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor() {
  }

  public static LOGIN_URL = `${UrlService.baseUrl()}auth/login`;
  public static CLIENT_URL = `${UrlService.baseUrl()}client`;
  public static ENQ_URL = `${UrlService.baseUrl()}client/enquiry`;
  public static CLIENT_SERVICE_INFO_URL = `${UrlService.baseUrl()}client-service-info`;
  public static CLIENT_PROFILE_URL = `${UrlService.baseUrl()}client-profile`;
  public static USER_URL = `${UrlService.baseUrl()}user`;
  public static COUNTRY_URL = `${UrlService.baseUrl()}country`;
  public static UNIVERSITY_URL = `${UrlService.baseUrl()}university`;
  public static SERVICE_PROVIDED_URL = `${UrlService.baseUrl()}service-provided`;
  public static COURSE_URL = `${UrlService.baseUrl()}course`;
  public static COMMENT_URL = `${UrlService.baseUrl()}comment`;
  public static FOLLOW_UP_URL = `${UrlService.baseUrl()}follow-up`;
  public static NOTIFICATION_URL = `${UrlService.baseUrl()}notification`;
  public static COMPANY_URL = `${UrlService.baseUrl()}company`;
  public static EXPORT_URL = `${UrlService.baseUrl()}export`;
  public static EXTRA_URL = `${UrlService.baseUrl()}extra`;
  public static INVOICE_URL = `${UrlService.baseUrl()}invoice`;

  private static baseUrl(): string {
    return 'https://app.lodestareduinternational.co.in/api/';

    if (!environment.production) {
      return 'http://localhost:4000/';
    } else {
      return 'https://app.lodestareduinternational.co.in/api/';
    }
  }

}
