export enum PermissionObj {
    CHANGE_CLIENT_STATUS = 'changeStatus',
    EDIT_SERVICE_AMT = 'serviceAmount',
    LOGIN = 'login',
    MANAGE_USER = 'manage',
    EXTRA_DATA = 'extra',
    COMPANY = 'company',
    SERVICE_PROVIDED = 'service',
    COURSE = 'course',
    COUNTRY = 'country',
    UNIVERSITY = 'university',
    EXPORT_EXCEL = 'exportExcel',
    SEARCH_COURSE = 'searchCourse',
    EDIT_COURSE_STATUS = 'courseStatus',
    ENQUIRY = 'enquiry',
    INVOICE = 'invoice'
}
