import { Component, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { CourseModel } from 'src/app/routes/course/course.model';

@Component({
  selector: 'app-course-name',
  templateUrl: './course-name.component.html',
  styleUrls: ['./course-name.component.scss']
})
export class CourseNameComponent implements OnChanges {
  @Input() id: string;
  public courseName: string;
  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnChanges(): void {
    let courseList: CourseModel[];
    const courseList$: Subscription = this.store.pipe(take(1)).subscribe(s => courseList = s.course.data);
    courseList$.unsubscribe();
    const filteredCourse = courseList
      .filter((c: CourseModel) => c._id === this.id)
      .map(c => c.name);
    this.courseName = filteredCourse?.length ? filteredCourse[0] : 'NA';
  }
}
