import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { CourseModel } from 'src/app/routes/course/course.model';

@Component({
  selector: 'app-course-list-selector',
  templateUrl: './course-list-selector.component.html',
  styleUrls: ['./course-list-selector.component.scss']
})
export class CourseListSelectorComponent implements OnInit {

  @Input('perferredSelector') perferredSelector: string = '_id';
  @Input('ngModelV') ngModelV: string[] = [];
  @Input('multiple') multiple: boolean = true;
  @Output() courseList = new EventEmitter<any>();

  public placeholder: 'Course' | 'Loading' | 'No Course to list' | 'Please reload' = 'Loading';

  /** list of courses */
  protected courses: CourseModel[] = [];

  /** control for the selected bank for multi-selection */
  public courseMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public courseMultiFilterCtrl: FormControl = new FormControl();

  /** list of courses filtered by search keyword */
  public filteredCourseMulti: ReplaySubject<CourseModel[]> = new ReplaySubject<CourseModel[]>(1);

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();


  constructor(
    private store: Store<AppState>,
  ) {
    this._loadCourse();
  }

  private async _loadCourse() {
    try {
      const courseList$: Subscription = this.store.pipe(take(1)).subscribe(s => this.courses = s.course.data);
      courseList$.unsubscribe();
      this.placeholder = this.courses?.length ? 'Course' : 'No Course to list';
    } catch (error) {
      this.placeholder = 'Please reload';
    }
  }

  ngOnInit() {
    // set initial selection
    this.courseMultiCtrl.setValue(this.ngModelV || []);

    // load the initial bank list
    this.filteredCourseMulti.next(this.courses.slice());

    // listen for search field value changes
    this.courseMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCourseMulti();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.filteredCourseMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.multiSelect.compareWith = (a: string, b: string) => a === b
      });
  }

  protected filterCourseMulti() {
    if (!this.courses) {
      return;
    }
    // get the search keyword
    let search = this.courseMultiFilterCtrl.value;
    if (!search) {
      this.filteredCourseMulti.next(this.courses.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the courses
    this.filteredCourseMulti.next(
      this.courses.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  public onSelectionChange() {
    this.courseList.emit(this.courseMultiCtrl?.value || []);
  }
}
