<mat-sidenav-container class="sidenav-container">
  <mat-sidenav class="sidenav" #drawer fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="!(isHandset$ | async)">
    <!-- ADD LOGO -->
    <div class="text-center py-4">
      <img style="width: 100px;" src="../../../../assets/logo_dark.png" alt="logo">
    </div>

    <!-- MENU LIST -->
    <mat-nav-list>
      <mat-list-item routerLink="/dashboard" routerLinkActive="active-menu" (click)="closeSideNav()">
        <mat-icon matListIcon>dashboard</mat-icon>
        <a matLine>Dashboard</a>
      </mat-list-item>

      <mat-list-item routerLink="/client" routerLinkActive="active-menu" (click)="closeSideNav()">
        <mat-icon matListIcon>supervisor_account</mat-icon>
        <a matLine>Client</a>
      </mat-list-item>

      <mat-list-item routerLink="/enquiry" routerLinkActive="active-menu" (click)="closeSideNav()">
        <mat-icon matListIcon>perm_phone_msg</mat-icon>
        <a matLine>Enquiry</a>
      </mat-list-item>

      <mat-list-item *ngIf="userDataAndPermissions.searchCourse" routerLink="/search" routerLinkActive="active-menu"
        (click)="closeSideNav()">
        <mat-icon matListIcon>search</mat-icon>
        <a matLine>Search Course</a>
      </mat-list-item>

      <mat-list-item *ngIf="userDataAndPermissions.invoice" routerLink="/invoice" routerLinkActive="active-menu"
        (click)="closeSideNav()">
        <mat-icon matListIcon>receipt</mat-icon>
        <a matLine>Invoice</a>
      </mat-list-item>

      <mat-list-item *ngIf="userDataAndPermissions.country" routerLink="/country" routerLinkActive="active-menu"
        (click)="closeSideNav()">
        <mat-icon matListIcon>flag</mat-icon>
        <a matLine>Country</a>
      </mat-list-item>

      <mat-list-item *ngIf="userDataAndPermissions.university" routerLink="/universities" routerLinkActive="active-menu"
        (click)="closeSideNav()">
        <mat-icon matListIcon>location_city</mat-icon>
        <a matLine>Universities</a>
      </mat-list-item>

      <mat-list-item *ngIf="userDataAndPermissions.course" routerLink="/course" routerLinkActive="active-menu"
        (click)="closeSideNav()">
        <mat-icon matListIcon>school</mat-icon>
        <a matLine>Course</a>
      </mat-list-item>


      <mat-list-item *ngIf="userDataAndPermissions.extra" routerLink="/extra" routerLinkActive="active-menu"
        (click)="closeSideNav()">
        <mat-icon matListIcon>source</mat-icon>
        <a matLine>Extra</a>
      </mat-list-item>

      <mat-list-item *ngIf="userDataAndPermissions.service" routerLink="/service-provided"
        routerLinkActive="active-menu" (click)="closeSideNav()">
        <mat-icon matListIcon>volunteer_activism</mat-icon>
        <a matLine>Service provided</a>
      </mat-list-item>

      <mat-list-item *ngIf="userDataAndPermissions.user" routerLink="/users" routerLinkActive="active-menu"
        (click)="closeSideNav()">
        <mat-icon matListIcon>person</mat-icon>
        <a matLine>Users</a>
      </mat-list-item>

      <mat-list-item *ngIf="userDataAndPermissions.company" routerLink="/company" routerLinkActive="active-menu"
        (click)="closeSideNav()">
        <mat-icon matListIcon>store</mat-icon>
        <a matLine>Company</a>
      </mat-list-item>

    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar>
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <!-- *ngIf="isHandset$ | async" -->
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="example-spacer"></span>

      <button (click)="notificationHelper.show = !notificationHelper.show" mat-icon-button
        [matBadge]="notificationHelper.count" [matBadgeHidden]="!notificationHelper.count" matBadgeOverlap="true"
        matBadgeSize="small">
        <mat-icon>
          notifications_active
        </mat-icon>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <div class="text-center mx-2 py-2">
          <b>{{userDataAndPermissions.userName}}</b>
          <div>
            <small>{{userDataAndPermissions.userRole}}</small>
          </div>
        </div>
        <button (click)="logout()" mat-menu-item>
          <mat-icon>power_settings_new</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>

    </mat-toolbar>
    <div>
      <app-notification *ngIf="notificationHelper.show" [notifications]="notifications" (loadAll)="loadAll($event)"
        (closeMe)="notificationHelper.show = false" (markAsReadEmit)="markAsReadEmit($event)"></app-notification>
    </div>
    <div class="ylb-app-content">
      <ng-content></ng-content>
    </div>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
