import { Action } from '@ngrx/store';

export const GET_UNIVERSITY_LIST_SERVER = 'GET_UNIVERSITY_LIST_SERVER';
export const ADD_NEW_UNIVERSITY = 'ADD_NEW_UNIVERSITY';
export const DELETE_UNIVERSITY = 'DELETE_UNIVERSITY';
export const UNIVERSITY_LIST_SERVER = 'UNIVERSITY_LIST_SERVER';
// export const TIME_HOP_COMPLETED = 'Time hop completed';

export class GetListOfUniversity implements Action {
  readonly type: string = GET_UNIVERSITY_LIST_SERVER;

  constructor(public payLoad: any) {
    console.log(`[ACTION] ${GET_UNIVERSITY_LIST_SERVER}`);
  }
}
// TIME_HOP_COMPLETED
export class SuccessUniversityComplete implements Action {
  readonly type: string = UNIVERSITY_LIST_SERVER;

  constructor(public payLoad: any) {
    console.log(`[ACTION] ${UNIVERSITY_LIST_SERVER}`);
  }
}
export class addNewUniversity implements Action {
  readonly type: string = ADD_NEW_UNIVERSITY;

  constructor(public payLoad: any) {
    console.log(`[ACTION] ${ADD_NEW_UNIVERSITY}`);
  }
}

export class deleteUniversity implements Action {
  readonly type: string = DELETE_UNIVERSITY;

  constructor(public payLoad: any) {
    console.log(`[ACTION] ${DELETE_UNIVERSITY}`);
  }
}

export type UniversityAction = GetListOfUniversity | addNewUniversity | deleteUniversity | SuccessUniversityComplete;
