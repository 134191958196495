import { Injectable } from '@angular/core';
/**
 * Init State values
 */
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { Logout } from 'src/app/logout.state';
import { GetListOfCountry } from 'src/app/routes/country/state';
import { GetListOfCourse } from 'src/app/routes/course/state';
import { GetListOfServiceProvided } from 'src/app/routes/service-provided/state';
import { GetListOfUniversity } from 'src/app/routes/university/state';
import { GetListOfUser } from 'src/app/routes/user/state';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  constructor(
    private store: Store<AppState>,
  ) { }
  public loadStoreData(forceLoad?: boolean): void {
    this.store.dispatch(new GetListOfCourse(forceLoad));
    this.store.dispatch(new GetListOfCountry(forceLoad));
    this.store.dispatch(new GetListOfUniversity(forceLoad));
    this.store.dispatch(new GetListOfServiceProvided(forceLoad));
    this.store.dispatch(new GetListOfUser(forceLoad));
  };

  public loadCountryStore(forceLoad?: boolean): void {
    this.store.dispatch(new GetListOfCountry(forceLoad));
  }

  public loadUniversityStore(forceLoad?: boolean): void {
    this.store.dispatch(new GetListOfUniversity(forceLoad));
  }

  /**
   * resetState
   */
  public resetState() {
    this.store.dispatch(new Logout());
  }
}
