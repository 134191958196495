import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppState, getCountryList } from 'src/app/app.state';
import { CountryModel } from 'src/app/routes/country/country.model';

@Component({
  selector: 'app-country-name',
  templateUrl: './country-name.component.html',
  styleUrls: ['./country-name.component.scss']
})
export class CountryNameComponent implements OnChanges {
  @Input() id: string;
  public countryName: string;
  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnChanges(): void {
    let countryList: CountryModel[];
    const countryList$: Subscription = this.store.pipe(take(1)).subscribe(s => countryList = s.country.data);
    countryList$.unsubscribe();
    const filteredCountry = countryList
      .filter((c: CountryModel) => c._id === this.id)
      .map(c => c.name);
    this.countryName = filteredCountry?.length ? filteredCountry[0] : 'NA';
  }
}
