import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RoutesModule } from './routes/routes.module';
import { SharedModule } from './shared';
import { LayoutModule } from './layout/layout.module';
import { CoreModule } from './core/core.module';
import { httpInterceptorProviders } from './core/interceptor';
import { ToastrModule } from 'ngx-toastr';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { effects, initialState, reducers } from './app.state';
import { EffectsModule } from '@ngrx/effects';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { StoreService } from './shared/services/store.service';
import { clearState } from './logout.state';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgChartsModule } from 'ng2-charts';
// import { MatNativeDateModule } from '@angular/material';
// import { MatMomentDateModule } from "@angular/material-moment-adapter";
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    RoutesModule,
    LayoutModule,
    CoreModule,
    ToastrModule.forRoot(),
    StoreModule.forRoot(reducers, { initialState, metaReducers: [clearState] }),
    StoreDevtoolsModule.instrument({ maxAge: 10 }),
    EffectsModule.forRoot(effects),
    NgxDaterangepickerMd.forRoot(),
    // NgxDatatableModule.forRoot({
    //   messages: {
    //     emptyMessage: 'No data to display', // Message to show when array is presented, but contains no values
    //     totalMessage: 'total', // Footer total message
    //     selectedMessage: 'selected' // Footer selected message
    //   }
    // })
    MatNativeDateModule,
    NgChartsModule
  ],
  providers: [
    httpInterceptorProviders,
    StoreService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
