import { Action } from '@ngrx/store';

export const GET_COUNTRY_LIST_SERVER = 'GET_COUNTRY_LIST_SERVER';
export const ADD_NEW_COUNTRY = 'ADD_NEW_COUNTRY';
export const DELETE_COUNTRY = 'DELETE_COUNTRY';
export const COUNTRY_LIST_SERVER = 'COUNTRY_LIST_SERVER';

export class GetListOfCountry implements Action {
  readonly type: string = GET_COUNTRY_LIST_SERVER;

  constructor(public payLoad: any) {
    console.log(`[ACTION] ${GET_COUNTRY_LIST_SERVER}`);
  }
}
export class SuccessCountryComplete implements Action {
  readonly type: string = COUNTRY_LIST_SERVER;

  constructor(public payLoad: any) {
    console.log(`[ACTION] ${COUNTRY_LIST_SERVER}`);
  }
}
export class addNewCountry implements Action {
  readonly type: string = ADD_NEW_COUNTRY;

  constructor(public payLoad: any) {
    console.log(`[ACTION] ${ADD_NEW_COUNTRY}`);
  }
}

export class deleteCountry implements Action {
  readonly type: string = DELETE_COUNTRY;

  constructor(public payLoad: any) {
    console.log(`[ACTION] ${DELETE_COUNTRY}`);
  }
}

export type CountryAction = GetListOfCountry | addNewCountry | deleteCountry | SuccessCountryComplete;
