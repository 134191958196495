import { Injectable } from '@angular/core';
import { Permission, UserRoles } from 'src/app/routes/user/user.model';
import { ApplicationConstants } from 'src/app/shared/constants/application';
import { PermissionObj } from 'src/app/shared/constants/permission';
import { LocalStorageService } from '../../shared';

export const USER_KEY = 'usr';

@Injectable({
  providedIn: 'root'
})
export class SettingService {


  constructor(private store: LocalStorageService) {
  }

  setUser(value: any) {
    this.store.set(USER_KEY, value);
  }

  getUser(): UserData {
    return this.store.get(USER_KEY);
  }

  removeUser() {
    this.store.remove(USER_KEY);
  }

  checkPermission(type: PermissionObj): boolean {
    const user = this.getUser();
    const userPermissionHandler = new UserPermissionHandler(user);
    const isSuperAdmin = userPermissionHandler.isSuperAdmin();
    const hasPermission = userPermissionHandler.hasPermission(type);
    /** If user is SUPER ADMIN or has permission */
    return isSuperAdmin || hasPermission;
  }

  isSuperAdmin(): boolean {
    const user: UserData = this.getUser();
    return new UserPermissionHandler(user).isSuperAdmin();
  }

  public setCompany(id) {
    const userInfo = this.getUser();
    const companyId = userInfo?.company;
    return (companyId === ApplicationConstants.allCompanyId)
      ? id
      : companyId;
  }
}

export class UserPermissionHandler {
  private readonly _user: UserData;

  constructor(user: UserData) {
    this._user = user;
  }

  public isSuperAdmin(): boolean {
    return this._user?.username === 'admin';
  }

  public hasPermission(type: PermissionObj): boolean {
    return this.isSuperAdmin() || this._user?.permission?.[type]
  }

  public isAllCompany(): boolean {
    return this._user.company === ApplicationConstants.allCompanyId;
  }

  public setCompany(id) {
    const companyId = this._user?.company;
    return (companyId === ApplicationConstants.allCompanyId)
      ? id
      : companyId;
  }
}

export interface UserData {
  company: string;
  isActive: boolean;
  name: string;
  permission: Permission;
  userRole: UserRoles[];
  username: string;
  _id: string;
}