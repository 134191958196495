import {Component, OnInit} from '@angular/core';
import {SettingService, TokenService} from '../../core';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../app.state';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  banana$: Observable<any>;
  constructor(private token: TokenService,
              private store: Store<AppState>,
              private router: Router,
              private settings: SettingService) {
  }

  ngOnInit(): void {
  }

  signOut(): void {
    this.token.clear();
    this.settings.removeUser();
    this.router.navigateByUrl('/auth/login');

  }
}
