import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { UniversityModel } from 'src/app/routes/university/university.model';

@Component({
  selector: 'app-uni-name',
  templateUrl: './uni-name.component.html',
  styleUrls: ['./uni-name.component.scss']
})
export class UniNameComponent implements OnChanges {
  @Input() id: string;
  public uniName: string;
  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnChanges(): void {
    let uniList: UniversityModel[];
    const uniList$: Subscription = this.store.pipe(take(1)).subscribe(s => uniList = s.university.data);
    uniList$.unsubscribe();
    const filteredUni = uniList
      .filter((c: UniversityModel) => c._id === this.id)
      .map(c => c.name);
    this.uniName = filteredUni?.length ? filteredUni[0] : 'NA';
  }
}
