import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../../shared/services/http.service';
import { UrlService } from '../../shared/services/url.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { ApplicationConstants } from 'src/app/shared/constants/application';
import { SettingService, UserPermissionHandler } from 'src/app/core';
import { CompanyModel } from '../company/company.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { Chart1Component } from './components/chart1/chart1.component';
import { LazyDataLoaderService } from 'src/app/shared/services/lazy-data-loader.service';
import { FollowUpHelper } from 'src/app/shared/models/follow-up-helper.model';
import { ClientType } from 'src/app/shared/models';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild('chart') chart: Chart1Component;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  public filter = this.resetFilter();
  dateConfig = {
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment()],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
    locale: { applyLabel: 'Apply', format: 'DD-MM-YYYY' }
  }

  public viewHelper = {
    payment: {
      loading: false,
      loaded: false,
      error: false,
      noData: false
    },
    count: {
      loading: false,
      loaded: false,
      error: false,
      noData: false
    },
    followUp: {
      loading: false,
      loaded: false,
      error: false,
      noData: false
    },
    isUpdating: false,
    getAll: true
  }
  public followUp: FollowUpHelper[];
  public clientList;
  public clientCounts = 0;
  public enqCounts = 0;
  public userPermission: any;
  public companyList: CompanyModel[];
  constructor(
    private httpService: HttpService,
    private toaster: ToastrService,
    private commonService: CommonService,
    private settings: SettingService,
    private lazyDataLoaderService: LazyDataLoaderService
  ) {
    this.userPermission = this._setUserPermissions();
    // this.loadClientByPayment();
    // this.clientCount();
    if (this.userPermission?.showCompanyDropdown) {
      this.loadCompany();
    }
  }
  private _setUserPermissions() {
    const userInfo = this.settings.getUser();
    const userP = new UserPermissionHandler(userInfo)
    return {
      showCompanyDropdown: userP.isAllCompany()
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.onCountrySelect();
    }, 0);
  }


  public onCountrySelect() {

    const filter = JSON.parse(JSON.stringify(this.filter));
    filter.company = this.settings.setCompany(filter.company);
    const urlFilterParms = new URLSearchParams(this.commonService.clearEmpties(filter)).toString();

    // this.chart.urlParams = urlFilterParms;
    this.chart.load(urlFilterParms);
    this.loadClientByPayment(urlFilterParms);
    this.clientCount(urlFilterParms);
    this.loadFolowUp(urlFilterParms)
  }

  public loadClientByPayment(urlFilterParms?: string): void {
    this.viewHelper.payment = { ...this.viewHelper, loading: true, loaded: false, error: false, noData: false };
    this.clientList = [];
    const url = `${UrlService.CLIENT_SERVICE_INFO_URL}/dashboard/payment${urlFilterParms ? '?' + urlFilterParms : ''}`
    this.httpService.fetchData(url)
      .toPromise()
      .then((res: any) => {
        try {
          this.clientList = res.data;
          this.clientList.forEach(element => {
            const clientInfo = element?.clientInfo;
            const name = `${clientInfo?.firstName || ''} ${clientInfo?.middleName || ''}${clientInfo?.lastName || ''}`.trim();
            element.name = name || 'Not found';
            // if (element?.payment?.feePaid?.length)
            element.payment.paid = element.payment?.feePaid?.reduce((previousValue, currentValue) => {
              return previousValue += currentValue.amount;
            }, 0);

            let days: number;
            element.payment.remaining = (element.payment.feeTotal - element.payment.paid);
            if (element.payment.nextDueDate) {
              element.payment.isDueDatePassed = moment().isAfter(element.payment.nextDueDate);
              element.payment.isDueDateToday = moment().format(ApplicationConstants.FULL_DATE) === moment(element.payment.nextDueDate).format(ApplicationConstants.FULL_DATE);
              days = moment().diff(element.payment.nextDueDate, 'days');
            } else {
              element.payment.paymentText = 'Next due date is not Added';
              element.payment.paymentTextClass = 'text-primary';
            }

            if (!element.payment.remaining) {
              element.payment.paymentText = 'Payment is completed';
              element.payment.paymentTextClass = 'text-success';
            } else if (element.payment.isDueDateToday) {
              element.payment.paymentText = 'Payment is due on Today';
              element.payment.paymentTextClass = 'text-danger';
            } else if (element.payment.isDueDatePassed) {
              element.payment.paymentText = `Payment is pending by ${Math.abs(days)} day's`;
              element.payment.paymentTextClass = 'text-danger';
            } else if (element.payment.nextDueDate) {
              element.payment.paymentText = `Payment date is in ${Math.abs(days)} day's`;
              element.payment.paymentTextClass = 'text-primary';
            }

          });

        } catch (error) {
          console.log(error);
        }

        this.viewHelper.payment.loaded = true;
      })
      .catch(() => this.viewHelper.payment.error = true)
      .finally(() => this.viewHelper.payment.loading = false);
  }

  loadFolowUp(urlFilterParms) {
    this.viewHelper.followUp = { ...this.viewHelper, loading: true, loaded: false, error: false, noData: false };
    const url = `${UrlService.FOLLOW_UP_URL}/dashboard${urlFilterParms ? '?' + urlFilterParms : ''}`
    this.httpService.fetchData(url)
      .toPromise()
      .then((res: any) => {
        // this.followUp = res.data?.count || 0
        this.viewHelper.followUp.loaded = true;
        this.followUp = res.data
          .map((x: FollowUpHelper) => new FollowUpHelper(x))
          .sort((x: FollowUpHelper) => x.followUpScheduleDate);
      })
      .catch(() => this.viewHelper.followUp.error = true)
      .finally(() => this.viewHelper.followUp.loading = false);
  }

  /**
   * clientCount
   */
  public clientCount(urlFilterParms: string) {
    this.viewHelper.count = { ...this.viewHelper, loading: true, loaded: false, error: false, noData: false };
    const url = `${UrlService.CLIENT_URL}/count${urlFilterParms ? '?' + urlFilterParms : ''}`
    this.httpService.fetchData(url)
      .toPromise()
      .then((res: any) => {
        const d = res.data
        if (d) {
          this.clientCounts = d.find(x => x._id === ClientType.CLIENT)?.totalRows || 0;
          this.enqCounts = d.find(x => x._id === ClientType.ENQUIRY)?.totalRows || 0;
        }
        this.viewHelper.count.loaded = true;
      })
      .catch(() => this.viewHelper.count.error = true)
      .finally(() => this.viewHelper.count.loading = false);
  }

  private resetFilter() {
    return {
      // createdAt: { startDate: moment().subtract(6, 'days').startOf('day'), endDate: moment().endOf('day') },
      company: null
    }
  }

  /**
   * onDateChange
   */
  // public onDateChange() {
  //   this.viewHelper.getAll = !this.viewHelper.getAll;
  // }

  private async loadCompany() {
    try {
      this.companyList = await this.lazyDataLoaderService.loadCompany();
    } catch (error) {

    }
  }

}
