import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core';
import { RouteGuard } from '../core/auth/route.guard';
import { AdminLayoutComponent } from '../layout/admin/admin.component';
import { AuthLayoutComponent } from '../layout/auth/auth.component';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        data: { title: 'Dashboard', permission: [] },
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [RouteGuard]
      },
      {
        path: 'client',
        data: { title: 'Clients', permission: [] },
        loadChildren: () => import('./client/client.module').then(m => m.ClientModule),
        canActivate: [RouteGuard]
      },
      {
        path: 'users',
        data: { title: 'Users', permission: ["MANAGE_USER"] },
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
        canActivate: [RouteGuard]
      },
      {
        path: 'country',
        data: { title: 'Country', permission: ["COUNTRY"] },
        loadChildren: () => import('./country/country.module').then(m => m.CountryModule),
        canActivate: [RouteGuard]
      },
      {
        path: 'universities',
        data: { title: 'Universities', permission: ["UNIVERSITY"] },
        loadChildren: () => import('./university/university.module').then(m => m.UniversityModule),
        canActivate: [RouteGuard]
      },
      {
        path: 'course',
        data: { title: 'Course', permission: ["COURSE"] },
        loadChildren: () => import('./course/course.module').then(m => m.CourseModule),
        canActivate: [RouteGuard]
      },
      {
        path: 'service-provided',
        data: { title: 'Service provided', permission: ["SERVICE_PROVIDED"] },
        loadChildren: () => import('./service-provided/service-provided.module').then(m => m.ServiceProvidedModule),
        canActivate: [RouteGuard]
      },
      {
        path: 'company',
        data: { title: 'Company', permission: ["COMPANY"] },
        loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
        canActivate: [RouteGuard]
      },
      {
        path: 'extra',
        data: { title: 'Extra', permission: ["EXTRA_DATA"] },
        loadChildren: () => import('./extra/extra.module').then(m => m.ExtraModule),
        canActivate: [RouteGuard]
      },
      {
        path: 'search',
        data: { title: 'Search', permission: ["SEARCH_COURSE"] },
        loadChildren: () => import('./search-course/search-course.module').then(m => m.SearchCourseModule),
        canActivate: [RouteGuard]
      },
      {
        path: 'invoice',
        data: { title: 'invoice', permission: ["INVOICE"] },
        loadChildren: () => import('./invoice/invoice-course.module').then(m => m.invoiceModule),
        canActivate: [RouteGuard]
      },
      {
        path: 'enquiry',
        data: { title: 'Search', permission: ["ENQUIRY"] },
        loadChildren: () => import('./enquiry/enquiry.module').then(m => m.EnquiryModule),
        canActivate: [RouteGuard]
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent, data: { title: 'Login' } },
      {
        path: 'register',
        component: RegisterComponent,
        data: { title: 'Register' },
      },
    ],
  },
  { path: '**', redirectTo: 'dashboard' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class RoutesRoutingModule {
}
