<div class="card p-4">
  <form (submit)="register()" [formGroup]="registerForm" #l>
    <h3 class="text-center">Register</h3>
    <hr>
    <!--EMAIL-->
    <div class="form-group" formGroupName="user">
      <label for="email">Email Id</label>
      <input autocomplete="off"
             formControlName="email" type="text" id="email" placeholder="Enter email Id" class="form-control">
      <div class="invalid-feedback" [class.d-block]="email.invalid && email.touched">
        <div *ngIf="email.hasError('required')">Please provide a email.</div>
        <div *ngIf="email.hasError('pattern')">Please provide a valid email.</div>
      </div>
    </div>
    <!--PASSWORD-->
    <div class="form-group" formGroupName="user">
      <label for="password">Password</label>
      <div class="input-group">
        <input formControlName="password" [type]="showPassword ? 'text' : 'password'"
               id="password"
               placeholder="Enter password"
               class="form-control">
        <div class="input-group-append">
        <span class="input-group-text"
              (click)="showPassword =! showPassword"
              id="basic-addon">
          <i [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'" class="fa"></i>
        </span>
        </div>
        <div class="invalid-feedback" [class.d-block]="password.invalid && password.touched">
          Please provide password.
        </div>
      </div>
    </div>
    <!--CHECK PASSWORD-->
    <div class="form-group">
      <label for="password_c">Confirm Password</label>
      <div class="input-group">
        <input formControlName="confirmPassword"
               [type]="showPassword ? 'text' : 'password'"
               id="password_c"
               placeholder="Enter password"
               class="form-control">
        <div class="invalid-feedback"
             [class.d-block]="(registerForm.hasError('passwordUnMatch') || cPassword.invalid) && cPassword.touched">
          <div>{{cPassword.invalid ? 'Please confirm password' : 'Password didn\'t match.' }}</div>
        </div>
      </div>
    </div>
    <!--BTN-->
    <div class="text-center">
      <button [disabled]="viewHelper.submitting"
              type="submit" class="btn btn-primary btn-block">Register
      </button>
    </div>
    <hr>
    <!--ACTIONS-->
    <div class="text-center">
      <div class="text-muted mt-2">have account ? <a routerLink="/auth/login">Login now</a></div>
    </div>
  </form>
</div>
