import { NgModule } from '@angular/core';
import { RoutesRoutingModule } from './routes.routing.module';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';
import { SharedModule } from '../shared';
import { DashboardModule } from './dashboard/dashboard.module';

/**
 * Mat
 */
import { MatSidenavModule } from '@angular/material/sidenav';
const COMPONENTS = [LoginComponent, RegisterComponent];
const COMPONENTS_DYNAMIC = [];

@NgModule({
  imports: [RoutesRoutingModule, SharedModule, DashboardModule, MatSidenavModule],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC],
  entryComponents: COMPONENTS_DYNAMIC,
})
export class RoutesModule {
}
