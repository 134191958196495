import { Inject, Injectable, Optional } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import { TokenService } from './token.service';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SettingService } from '../bootstrap/setting.service';

const LOGIN_URL = '/auth/login';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  private gotoLogin(url?: string) {
    setTimeout(() => {
      if (/^https?:\/\//g.test(url!)) {
        this.document.location.href = url as string;
      } else {
        this.router.navigateByUrl(url);
      }
    });
  }

  private checkJWT(model: any, offset?: number): boolean {
    return !!model?.token;
  }

  private process(): boolean {
    const res = this.checkJWT(this.token.get<any>(), 1000);
    const userInfo = this.settingService.getUser();
   
    if (userInfo && Object.keys(userInfo).length && res) {
      this.authService.setUserInfo(userInfo);
      this.authService.setAuth(res);
    } else {
      this.gotoLogin(LOGIN_URL);
    }

    return userInfo && Object.keys(userInfo).length && res;
  }

  constructor(
    private router: Router,
    private token: TokenService,
    private authService: AuthService,
    private settingService: SettingService,
    @Optional() @Inject(DOCUMENT) private document: any
  ) {
  }

  // lazy loading
  canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.process();
  }

  // route
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.process();
  }

  // all children route
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.process();
  }
}
