import { Action } from "@ngrx/store";

export function clearState(reducer) {
    return function (state, action) {
  
      if (action.type === ActionTypes.LOGOUT) {
        state = undefined;
      }
  
      return reducer(state, action);
    };
  }

  export class ActionTypes {
    static LOGOUT = "[App] logout";
  }
  
  export class Logout implements Action {
    readonly type = ActionTypes.LOGOUT;
  }