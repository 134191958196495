import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { GET_UNIVERSITY_LIST_SERVER, GetListOfUniversity, SuccessUniversityComplete } from './university.action';
import { map, switchMap } from 'rxjs/operators';
import { LazyDataLoaderService } from 'src/app/shared/services/lazy-data-loader.service';

@Injectable()
export class universityEffects {

    constructor(private lazy: LazyDataLoaderService, private actions$: Actions) {
        console.log('[EFFECT] : University');
    }

    @Effect()
    public GetListOfUniversity$ = this.actions$.pipe(
        ofType(GET_UNIVERSITY_LIST_SERVER),
        switchMap((action: GetListOfUniversity) => {            
            return this.lazy.getUniversityList(action.payLoad).pipe(
                map(
                    color => new SuccessUniversityComplete(color),
                    errors => console.log(errors)
                )
            );
        })
    );
}
