import * as programActions from './course.action';
import { ADD_NEW_COURSE, COURSE_LIST_SERVER, DELETE_COURSE } from './course.action';
import { CourseState } from './course.state';

export function reducer(state: CourseState, action: programActions.CourseAction): CourseState {
    switch (action.type) {
        case ADD_NEW_COURSE: {
            console.log(`REDUCER ${ADD_NEW_COURSE}`);
            const listOfCourse = state.data.push(action.payLoad)
            return {
                ...state, data: listOfCourse, updatedAt: new Date()
            };
        }
        case COURSE_LIST_SERVER: {
            console.log(`REDUCER ${COURSE_LIST_SERVER}`);

            return {
                ...state, ...action.payLoad
            };
        }
        // case PEEL_BANANA: {
        //     console.log(`REDUCER ${PEEL_BANANA}`);
        //     return {
        //         ...state, isPeeled: true
        //     };
        // }
        // case EAT_BANANA: {
        //     console.log(`REDUCER ${EAT_BANANA}`);
        //     return {
        //         ...state, bitesRemaining: (state.bitesRemaining - action.payLoad)
        //     };
        // }
        // case TIME_HOP_COMPLETED: {
        //     console.log(`REDUCER ${TIME_HOP_COMPLETED}`);
        //     return {
        //         ...state, color: action.payLoad
        //     };
        // }
        default: {
            return { ...state };
        }
    }
}
