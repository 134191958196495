<div class="d-flex justify-content-between align-items-center">
    <div [matBadge]="notifications.length" [matBadgeHidden]="!showAll" matBadgeOverlap="false" matBadgeSize="small">
        {{showAll ? 'All notifications' :'Unread notifications'}}</div>
    <small (click)="loadAllNotification()" class="text-primary pointer"> {{showAll ? 'Show unread' :'Show all'}}</small>
</div>
<hr class="my-2 mx-0">
<ng-container *ngIf="notifications?.length; else noNoti">
    <div class="content-div">
        <!-- notification?.clientInfo?.middleName + notification?.clientInfo?.lastName -->
        <div *ngFor="let notification of notifications;let last = last"
            [ngClass]="notification.isRead ? 'text-muted': 'text-bold'">

            <div class="d-flex justify-content-between" *ngIf="notification.metaData === 'COMMENT'">
                <small>
                    Comment added by {{notification.createdBy}}
                    <span
                        *ngIf="notification?.clientInfo?.firstName || notification?.clientInfo?.middleName || notification?.clientInfo?.lastName">
                        for
                        <span class="text-primary"
                            *ngIf="notification?.clientInfo?.firstName">{{notification?.clientInfo?.firstName}}</span>
                        <span class="text-primary"
                            *ngIf="notification?.clientInfo?.middleName">{{notification?.clientInfo?.middleName}}</span>
                        <span class="text-primary"
                            *ngIf="notification?.clientInfo?.lastName">{{notification?.clientInfo?.lastName}}</span>
                    </span>
                </small>
                <!-- <small class="text-secondary pointer"
                    [routerLink]="['/','client','details', notification.clientId]">Go to client</small> -->
                <small class="text-secondary pointer" (click)="goToClient(notification)">Go
                    to client</small>
            </div>
            {{notification.message || '-'}}
            <div class="d-flex justify-content-between">
                <small class="text-muted">
                    {{notification.createdAt | date : 'dd MMM yy hh:mm'}}
                </small>
                <small class="text-primary pointer" *ngIf="!notification.isRead" (click)="markAsRead(notification)">Mark
                    as
                    read</small>
            </div>
            <hr class="my-2 mx-0" *ngIf="!last">
        </div>
    </div>
</ng-container>

<ng-template #noNoti>
    <div class="text-center text-muted p-2">
        <small>No notification found</small>
    </div>
</ng-template>