import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { UniversityModel } from 'src/app/routes/university/university.model';

@Component({
  selector: 'app-uni-list-selector',
  templateUrl: './uni-list-selector.component.html',
  styleUrls: ['./uni-list-selector.component.scss']
})
export class UniListSelectorComponent implements OnInit {
  @Input('perferredSelector') perferredSelector: string = '_id';
  @Input('ngModelV') ngModelV: string[] = [];
  @Input('loadData') loadData: boolean = true;
  @Input('unis') unis: UniversityModel[] = [];
  @Output() uniList = new EventEmitter<any>();

  public placeholder: 'University' | 'Loading' | 'No University to list' | 'Please reload' = 'Loading';

  /** list of unis */
  // protected unis: UniversityModel[] = [];

  /** control for the selected bank for multi-selection */
  public uniMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public uniMultiFilterCtrl: FormControl = new FormControl();

  /** list of unis filtered by search keyword */
  public filteredUniMulti: ReplaySubject<UniversityModel[]> = new ReplaySubject<UniversityModel[]>(1);

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();


  constructor(
    private store: Store<AppState>,
  ) {
    if (this.loadData) {
      this._loadCourse();
    }
  }

  private async _loadCourse() {
    try {
      const uniList$: Subscription = this.store.pipe(take(1)).subscribe(s => this.unis = s.university.data);
      uniList$.unsubscribe();
      this.placeholder = this.unis?.length ? 'University' : 'No University to list';
    } catch (error) {
      this.placeholder = 'Please reload';
    }
  }

  ngOnInit() {
    // set initial selection
    this.uniMultiCtrl.setValue(this.ngModelV || []);
    // this.setInitialValue();

    // load the initial bank list
    this.filteredUniMulti.next(this.unis.slice());

    // listen for search field value changes
    this.uniMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterUniMulti();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.filteredUniMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.multiSelect.compareWith = (a: string, b: string) => a === b
      });
  }

  protected filterUniMulti() {
    if (!this.unis) {
      return;
    }
    // get the search keyword
    let search = this.uniMultiFilterCtrl.value;
    if (!search) {
      this.filteredUniMulti.next(this.unis.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the unis
    this.filteredUniMulti.next(
      this.unis.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  public onSelectionChange() {
    this.uniList.emit(this.uniMultiCtrl?.value || []);
  }
}
