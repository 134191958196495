import { Component } from '@angular/core';
import { distinctUntilChanged} from 'rxjs/operators';
import { AuthService } from './shared/services/auth.service';
import { StoreService } from './shared/services/store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private storeService: StoreService,
    private authService: AuthService
  ) {
    this.authService.isAuth
      .pipe(distinctUntilChanged())
      .subscribe(isAuth => {
        if (isAuth) { this.storeService.loadStoreData(); }
        else { this.storeService.resetState(); }
      });
  }
}
