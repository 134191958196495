import * as programActions from './user.action';
import { USER_LIST_SERVER } from './user.action';
import { UserState } from './user.state';

export function reducer(state: UserState, action: programActions.UserAction): UserState {
    switch (action.type) {
        case USER_LIST_SERVER: {
            console.log(`[REDUCER] ${USER_LIST_SERVER}`);

            return {
                ...state, ...action.payLoad
            };
        }
        default: {
            return { ...state };
        }
    }
}
