import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { SafeUrlPipe } from './pipes/safe-url.pipe';

/**
 * Confirm modal
 */
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CountryNameComponent } from './components/country-name/country-name.component';
import { UniNameComponent } from './components/uni-name/uni-name.component';
import { CourseNameComponent } from './components/course-name/course-name.component';
import { UserNameComponent } from './components/user-name/user-name.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShimmerComponent } from './components/shimmer/shimmer.component';
import { MdePopoverModule } from '@material-extended/mde';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatSelectModule } from '@angular/material/select';
import { CourseListSelectorComponent } from './components/course-list-selector/course-list-selector.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { UniListSelectorComponent } from './components/uni-list-selector/uni-list-selector.component';
import { NumberToWordPipe } from './pipes/custom-date-format.pipe';

const COMPONENTS = [
  ConfirmModalComponent,
  CountryNameComponent,
  UniNameComponent,
  CourseNameComponent,
  UserNameComponent,
  ShimmerComponent,
  CourseListSelectorComponent,
  UniListSelectorComponent
];
const COMPONENTS_DYNAMIC = [];
const DIRECTIVES = [];
const PIPES = [SafeUrlPipe];
const THIRD_MODULES = [];

@NgModule({
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, ...DIRECTIVES, ...PIPES, NumberToWordPipe],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    MdePopoverModule,
    NgxDaterangepickerMd,
    MatSelectModule,
    NgxMatSelectSearchModule,

    ReactiveFormsModule,
    ...THIRD_MODULES
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MdePopoverModule,
    NgxDaterangepickerMd,
    ...THIRD_MODULES,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
  ],
  entryComponents: COMPONENTS_DYNAMIC,
})
export class SharedModule {
}
