import { ActionReducerMap } from '@ngrx/store';
/**
 * Store folder index
 */
import * as courseStore from './routes/course/state';
import * as countryStore from './routes/country/state';
import * as universityStore from './routes/university/state';
import * as serviceProvidedStore from './routes/service-provided/state';
import * as userStore from './routes/user/state';

export interface AppState {
  country: countryStore.CountryState;
  course: courseStore.CourseState;
  university: universityStore.UniversityState;
  serviceProvided: serviceProvidedStore.ServiceProvidedState;
  user: userStore.UserState
}

export const initialState: AppState = {
  course: courseStore.initialState,
  country: countryStore.initialState,
  university: universityStore.initialState,
  serviceProvided: serviceProvidedStore.initialState,
  user: userStore.initialState
};

export const reducers: ActionReducerMap<AppState> = {
  course: courseStore.reducer,
  country: countryStore.reducer,
  university: universityStore.reducer,
  serviceProvided: serviceProvidedStore.reducer,
  user: userStore.reducer
};

export const effects: Array<any> = [
  courseStore.courseEffects,
  countryStore.countryEffects,
  universityStore.universityEffects,
  serviceProvidedStore.ServiceProvidedEffects,
  userStore.userEffects
];

export const getCourseList = (s: AppState) => s.course;
export const getCountryList = (s: AppState) => s.country;
export const getUniversityList = (s: AppState) => s.university;
export const getServiceProvidedList = (s: AppState) => s.serviceProvided;
export const getUserList = (s: AppState) => s.user;
