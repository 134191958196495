import * as programActions from './university.action';
import { ADD_NEW_UNIVERSITY, UNIVERSITY_LIST_SERVER, DELETE_UNIVERSITY } from './university.action';
import { UniversityState } from './university.state';

export function reducer(state: UniversityState, action: programActions.UniversityAction): UniversityState {
    switch (action.type) {
        case ADD_NEW_UNIVERSITY: {
            console.log(`[REDUCER] ${ADD_NEW_UNIVERSITY}`);
            const listOfCourse = state.data.push(action.payLoad)
            return {
                ...state, data: listOfCourse, updatedAt: new Date()
            };
        }
        case UNIVERSITY_LIST_SERVER: {
            console.log(`[REDUCER] ${UNIVERSITY_LIST_SERVER}`);

            return {
                ...state, ...action.payLoad
            };
        }
        default: {
            return { ...state };
        }
    }
}
