<div class="card p-4">
  <form (submit)="login()" [formGroup]="loginForm" #l="ngForm">
    <div class="text-center">
      <img style="height: 70px;" src="../../../../assets/logo_dark.png" alt="logo">
    </div>
    <hr>
    <!--username-->
    <div class="form-group">
      <label for="username">Username</label>
      <input autocomplete="off" formControlName="username" type="text" id="username" placeholder="Enter username"
        class="form-control">
      <div class="invalid-feedback" [class.d-block]="username.invalid && username.touched">
        <div *ngIf="username.hasError('required')">Please provide a username.</div>
        <div *ngIf="username.hasError('pattern')">Please provide a valid username.</div>
      </div>
    </div>
    <!--PASSWORD-->
    <div class="form-group">
      <label for="password">Password</label>
      <div class="input-group">
        <input formControlName="password" [type]="showPassword ? 'text' : 'password'" id="password"
          placeholder="Enter password" class="form-control">
        <div class="input-group-append">
          <span class="input-group-text" (click)="showPassword =! showPassword" id="basic-addon2">
            <i [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'" class="fa"></i>
          </span>
        </div>
        <div class="invalid-feedback" [class.d-block]="password.invalid && password.touched">
          Please provide password.
        </div>
      </div>
    </div>
    <!--BTN-->
    <div class="text-center">
      <button [disabled]="viewHelper.loggingIn" type="submit" class="btn btn-primary btn-block">Login
      </button>
    </div>
    <hr>
    <!--ACTIONS-->
    <!-- <div class="text-center">
      <div class="text-muted mt-2">No account ? <a routerLink="/auth/register">Register now</a></div>
    </div> -->
  </form>
</div>
