import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { UserRoles } from 'src/app/routes/user/user.model';
import { PermissionObj } from 'src/app/shared/constants/permission';
import { SettingService } from '..';
import { UserPermissionHandler } from '../bootstrap/setting.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {
  constructor(
    private router: Router,
    private settings: SettingService,
    private activatedRoute: ActivatedRoute
  ) {

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let hasPermission: boolean = false;
    const userInfo = this.settings.getUser();
    const userPermissionHandler = new UserPermissionHandler(userInfo);

    const permissionObj = route.data?.permission;
    if (permissionObj?.length) {
      hasPermission = permissionObj?.map(x => userPermissionHandler.hasPermission(PermissionObj[x]))?.every(x => !!x);
    } else {
      hasPermission = true;
    }
    // console.log(hasPermission)
    return true
  }

}
