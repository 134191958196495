import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { REGEX } from '../../../shared';
import { HttpService } from '../../../shared/services/http.service';
import { UrlService } from '../../../shared/services/url.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  get email() {
    return this.registerForm.controls.user.get('email');
  }

  get password() {
    return this.registerForm.controls.user.get('password');
  }

  get cPassword() {
    return this.registerForm.get('confirmPassword');
  }

  public showPassword = false;
  public registerForm: FormGroup;
  public viewHelper = {
    submitting: false
  };

  constructor(private fb: FormBuilder, private httpService: HttpService, private toster: ToastrService) {
    this.registerForm = this.fb.group({
      confirmPassword: [null, [Validators.required]],
      user: this.fb.group(
        {
          email: [null, [Validators.required, Validators.pattern(REGEX.email)]],
          password: [null, [Validators.required]],
        }
      )
    }, { validators: RegisterComponent.passwordConfirming });
  }

  private static passwordConfirming(f: AbstractControl): { passwordUnMatch: boolean } {
    if ((f[`controls`].user.get('password').value !== f.get('confirmPassword').value)) {
      return { passwordUnMatch: true };
    }
  }

  ngOnInit(): void {
  }

  public register(): void {
    this.registerForm.markAllAsTouched();
    if (this.registerForm.valid) {
      console.log(this.registerForm.controls.user.value);
      this.viewHelper.submitting = true;
      this.registerForm.disable();
      this.httpService.postData('URL', this.registerForm.controls.user.value)
        .subscribe(res => {
          this.toster.success(res?.[`message`] || 'Account created');
          this.viewHelper.submitting = false;
          this.registerForm.enable();
          this.registerForm.reset();
        }, error => {
          const err = JSON.parse(JSON.stringify(error));
          this.toster.error(err?.error?.[`error`] || 'Something went wrong');
          this.viewHelper.submitting = false;
          this.registerForm.enable();
        });
    }
  }
}
