import * as programActions from './service-provided.action';
import { SERVICE_PROVIDED_LIST_SERVER } from './service-provided.action';
import { ServiceProvidedState } from './service-provided.state';

export function reducer(state: ServiceProvidedState, action: programActions.ServiceProvidedAction): ServiceProvidedState {
    switch (action.type) {
        case SERVICE_PROVIDED_LIST_SERVER: {
            console.log(`[REDUCER] ${SERVICE_PROVIDED_LIST_SERVER}`);

            return {
                ...state, ...action.payLoad
            };
        }
        default: {
            return { ...state };
        }
    }
}
