import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from 'src/app/core';
import { ApplicationConstants } from '../../constants/application';
import { NotificationModel } from '../../models/notification.model';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input('notifications') notifications: NotificationModel[];
  @Output() markAsReadEmit = new EventEmitter<NotificationModel>();
  @Output() loadAll = new EventEmitter<boolean>();
  @Output() closeMe = new EventEmitter<boolean>();
  public showAll = false;
  private count = 0;
  constructor(
    private eRef: ElementRef,
    private router: Router,
    private settings: SettingService,
    private toastrService: ToastrService
  ) {
    this.loadAllNotification(false);
  }


  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.count++;
    if (this.eRef.nativeElement.contains(event.target)) {
      /** Add inside click */
    } else if (this.count > 1) {
      this.loadAllNotification(false);
      this.closeMe.emit();
    }
  }
  ngOnInit(): void {
  }

  markAsRead(notification: NotificationModel) {
    notification.isRead = true;
    this.markAsReadEmit.emit(notification);
  }

  goToClient(notification: NotificationModel) {
    if (this._isCompanyAccess(notification.company)) {
      if (!notification.isRead) {
        this.markAsRead(notification);
      }
      // this.router.navigateByUrl(`/client/details/${notification.clientId}`);
      this.router.navigate(['./', 'client', 'details', notification.clientId]);
    } else {
      this.toastrService.error('You didn\'t have permission to acces the client');
    }
  }

  private _isCompanyAccess(companyId: string): boolean {
    const company = this.settings.getUser().company;
    return company === ApplicationConstants.allCompanyId || companyId === company;
  }

  loadAllNotification(showAll?: boolean) {
    this.showAll = showAll ?? !this.showAll;
    this.loadAll.emit(this.showAll);
  }

}
