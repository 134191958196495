import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { GET_SERVICE_PROVIDED_LIST_SERVER, GetListOfServiceProvided, SuccessServiceProvidedComplete } from './service-provided.action';
import { map, switchMap } from 'rxjs/operators';
import { LazyDataLoaderService } from 'src/app/shared/services/lazy-data-loader.service';

@Injectable()
export class ServiceProvidedEffects {

    constructor(private lazy: LazyDataLoaderService, private actions$: Actions) {
        console.log('[EFFECT] : ServiceProvided');
    }

    @Effect()
    public GetListOfServiceProvided$ = this.actions$.pipe(
        ofType(GET_SERVICE_PROVIDED_LIST_SERVER),
        switchMap((action: GetListOfServiceProvided) => {
            return this.lazy.getServiceProvidedList(action.payLoad).pipe(
                map(
                    (res) => new SuccessServiceProvidedComplete(res),
                    (errors: any) => console.log(errors)
                )
            );
        })
    );
}
