import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserModel } from 'src/app/routes/user/user.model';
import { LocalStorageService } from '..';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isAuth = new BehaviorSubject(false);
  public userInfo = new Subject();
  constructor(
    private localStorageService: LocalStorageService
  ) { }
  public setAuth(isAuth: boolean) {
    this.isAuth.next(isAuth)
  }

  public setUserInfo(user?: UserModel) {
    this.userInfo.next(user);
  }

  public getUserName() {
    return this.localStorageService.get('usr')?.name ?? 'UNKNOWN'
  }

  public getUserId() {
    return this.localStorageService.get('usr')?._id ?? 'UNKNOWN'
  }

  public getCompanyId() {
    return this.localStorageService.get('usr')?.company ?? 'UNKNOWN'
  }
}
