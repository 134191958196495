import * as programActions from './country.action';
import { ADD_NEW_COUNTRY, COUNTRY_LIST_SERVER, DELETE_COUNTRY } from './country.action';
import { CountryState } from './country.state';

export function reducer(state: CountryState, action: programActions.CountryAction): CountryState {
    switch (action.type) {
        case ADD_NEW_COUNTRY: {
            console.log(`REDUCER ${ADD_NEW_COUNTRY}`);
            const listOfCourse = state.data.push(action.payLoad)
            return {
                ...state, data: listOfCourse, updatedAt: new Date()
            };
        }
        case COUNTRY_LIST_SERVER: {
            console.log(`REDUCER ${COUNTRY_LIST_SERVER}`);

            return {
                ...state, ...action.payLoad
            };
        }
        // case PEEL_BANANA: {
        //     console.log(`REDUCER ${PEEL_BANANA}`);
        //     return {
        //         ...state, isPeeled: true
        //     };
        // }
        // case EAT_BANANA: {
        //     console.log(`REDUCER ${EAT_BANANA}`);
        //     return {
        //         ...state, bitesRemaining: (state.bitesRemaining - action.payLoad)
        //     };
        // }
        // case TIME_HOP_COMPLETED: {
        //     console.log(`REDUCER ${TIME_HOP_COMPLETED}`);
        //     return {
        //         ...state, color: action.payLoad
        //     };
        // }
        default: {
            return { ...state };
        }
    }
}
