import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { ConfirmModalComponent, DialogData } from '../components/confirm-modal/confirm-modal.component';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(public dialog: MatDialog, private store: Store<AppState>) { }

  openDialog(data: DialogData, cb: any) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, { data });

    dialogRef.afterClosed().subscribe(result => {
      cb(result)
    });
  }

  public clearEmpties(o) {
    const x = {};
    for (var k in o) {
      if (o[k]) x[k] = o[k];
    }
    return x;
  }
}
