import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {TokenService} from '../../core';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient,
              private token: TokenService) {
  }

  private getHeaders() {

    const auth = '';

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    });
    return headers;
  }

  fetchData(url) {
    const httpOptions = {
      headers: this.getHeaders()
    };
    return this.http.get(url, httpOptions).pipe(map(res => res ? res : res));
  }

  postData(url, formData) {

    return this.http.post(url, formData, {
      headers: this.getHeaders()
    }).pipe(map(res => res ? res : res));
  }

  putData(url, formData) {

    return this.http.put(url, formData, {
      headers: this.getHeaders()
    }).pipe(map(res => res ? res : res));
  }

  patchData(url, formData) {

    return this.http.patch(url, formData, {
      headers: this.getHeaders()
    }).pipe(map(res => res ? res : res));
  }

  deleteData(url) {
    return this.http.delete(url, {
      headers: this.getHeaders()
    }).pipe(map(res => res ? res : res));
  }

  fetchFileData(url) {

    return this.http.get(url, {
      responseType: 'blob',
      headers: this.getHeaders()
    });
  }

  postDataWithFile(url, formData) {

    const auth = '';
    const headers = new HttpHeaders({
      Authorization: auth
    });
    return this.http.post(url, formData, {headers}).pipe(map(res => res ? res : res));

  }

  uploadFileAndGetAndGetFileData(url, file): any {
    const formData: FormData = new FormData();
    formData.append('image', file, file.name);
    return this.postDataWithFile(url, formData);
  }
}
