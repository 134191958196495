import * as moment from "moment";
import { FollowUp } from "./follow-up";
import timesago from 'timesago'

export enum FollowUpStatus {
    DONE = 'Follow up is done',
    UPCOMMING = 'Follow up is upcoming',
    OVER_DUE = 'Follow up is overdue',
    TODAY = 'Follow up is planned for today',
}

export class FollowUpHelper extends FollowUp {

    status: FollowUpStatus;
    statusClass: string;
    description: string;
    descriptionClass: string;
    clientName: string;
    clientInfo?: {
        firstName: string;
        isActive: boolean
        lastName: string;
        middleName: string;
    }
    constructor(i: FollowUpHelper) {
        super(i);
        const { firstName, lastName, middleName } = i?.clientInfo || {};
        this.clientName = (firstName || lastName || middleName) ? (firstName ?? '') + (middleName ? ` ${middleName}` : '') + (lastName ? ` ${lastName}` : '') : '';
        const { status, description, statusClass, descriptionClass } = this._statusGen(i);
        this.status = status;
        this.description = description;
        this.statusClass = statusClass;
        this.descriptionClass = descriptionClass;
    }

    private _statusGen(d: FollowUp): {
        status: FollowUpStatus;
        description: string;
        statusClass: string;
        descriptionClass: string;
    } {
        let description: string;
        let statusClass: string = 'badge-success';
        let status: FollowUpStatus;
        let descriptionClass: string = 'text-success';
        const currentDate = new Date().toISOString();
        const timeDiff = timesago(d.followUpScheduleDate, {
            now: d.followUpDate ? new Date(d.followUpDate).valueOf() : Date.now()
        });
        if (d.followUpDate) {
            status = FollowUpStatus.DONE;
            statusClass = 'badge-success';

            const isFollwUpDelyed = moment(d.followUpDate).isAfter(d.followUpScheduleDate);
            if (isFollwUpDelyed) {
                description = `Follow up was scheduled ${timeDiff}`;
                descriptionClass = 'text-danger';
            } else {
                description = `Follow up done ${timeDiff.replace("in ", "")} before scheduled time`;
                descriptionClass = 'text-success';
            }
        } else {
            const isOverDue = moment(currentDate).isAfter(d.followUpScheduleDate);

            if (isOverDue) {
                status = FollowUpStatus.OVER_DUE;
                statusClass = 'badge-danger';
                description = `Follow up was scheduled ${timeDiff}`;
                descriptionClass = 'text-danger';
            } else {
                status = FollowUpStatus.UPCOMMING;
                statusClass = 'badge-warning';
                descriptionClass = 'text-warning';
                description = `Follow up is ${timeDiff}`;
            }

        };

        return { status: status, description, statusClass, descriptionClass };
    }
}