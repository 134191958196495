import { Action } from '@ngrx/store';
export const GET_SERVICE_PROVIDED_LIST_SERVER = 'GET_SERVICE_PROVIDED_LIST_SERVER';
export const SERVICE_PROVIDED_LIST_SERVER = 'SERVICE_PROVIDED_LIST_SERVER';

export class GetListOfServiceProvided implements Action {
  readonly type: string = GET_SERVICE_PROVIDED_LIST_SERVER;

  constructor(public payLoad: any) {
    console.log(`[ACTION] ${GET_SERVICE_PROVIDED_LIST_SERVER}`);
  }
}

export class SuccessServiceProvidedComplete implements Action {
  readonly type: string = SERVICE_PROVIDED_LIST_SERVER;

  constructor(public payLoad: any) {
    console.log(`[ACTION] ${SERVICE_PROVIDED_LIST_SERVER}`);
  }
}

export type ServiceProvidedAction = GetListOfServiceProvided | SuccessServiceProvidedComplete;
