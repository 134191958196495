import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { GET_COUNTRY_LIST_SERVER, GetListOfCountry, SuccessCountryComplete } from './country.action';
import { map, switchMap } from 'rxjs/operators';
import { LazyDataLoaderService } from 'src/app/shared/services/lazy-data-loader.service';

@Injectable()
export class countryEffects {

    constructor(private lazy: LazyDataLoaderService, private actions$: Actions) {
        console.log('[EFFECT] : Country');
    }

    @Effect()
    public GetListOfCountry$ = this.actions$.pipe(
        ofType(GET_COUNTRY_LIST_SERVER),
        switchMap((action: GetListOfCountry) => {
            return this.lazy.getCountryList(action.payLoad).pipe(
                map(
                    res => new SuccessCountryComplete(res),
                    errors => console.log(errors)
                )
            );
        })
    );
}
