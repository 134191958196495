<!-- <div *ngIf="false" class="mt-2 d-flex justify-content-center">
  <input alwaysShowCalendars="true" *ngIf="!viewHelper.getAll" (datesUpdated)="onDateChange()"
    [locale]="dateConfig.locale" [ranges]="dateConfig.ranges" type="text" placeholder="Choose date range"
    ngxDaterangepickerMd [(ngModel)]="filter.createdAt" class="form-control pointer col-md-4 text-center" />

  <button [matTooltip]="!viewHelper.getAll ? 'Click to select All' :'Click to select by time range'"
    (click)="onDateChange()" class="mr-1" [color]="viewHelper.getAll ? 'primary': ''" type="button" mat-raised-button>
    Get All
  </button>
</div> -->
<div class="mt-1">
  <mat-form-field *ngIf="userPermission.showCompanyDropdown" class="col-md-4 col-sm-6 col-12">
    <mat-label>Company</mat-label>
    <mat-select (selectionChange)="onCountrySelect()" name="companyList" [(ngModel)]="filter.company">
      <mat-option value="">
        ALL
      </mat-option>

      <mat-option *ngFor="let company of companyList" [value]="company._id">
        {{company.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="pt-2 d-flex flex-wrap">

  <div class="col-md-4">
    <mat-card class="d-flex flex-wrap mb-2">
      <div class="col-md-6">
        <h4 class="text-muted">Clients</h4>
        <h1 class="text-left">{{clientCounts}}</h1>
      </div>
      <div class="col-md-6">
        <h4 class="text-muted">Enquiry</h4>
        <h1 class="text-left">{{enqCounts}}</h1>
      </div>
    </mat-card>
    <!-- CHART -->
    <mat-card class="col-12">
      <app-chart1 #chart></app-chart1>
    </mat-card>
  </div>

  <div class="col-md-4">
    <mat-card>
      <h5 class="mb-1 text-muted">Enquiry Follow up</h5>
      <app-shimmer *ngIf="viewHelper.followUp?.loading"></app-shimmer>
      <div *ngIf="viewHelper.followUp?.loaded && followUp?.length" class="payment-list pr-1">
        <div *ngFor="let fu of followUp; let last = last;">
          <div class="d-flex justify-content-between align-items-center">
            <span matTooltip="Go to advance page" matTooltipPosition="above" class="pointer"
              [routerLink]="['/','enquiry','details', fu.clientId]" [queryParams]="{tabIndex : '1'}">
              {{fu.clientName}}
            </span>
            <small matTooltip="Expected follow up date" matTooltipPosition="above" class="text-muted pointer">
              {{fu.followUpScheduleDate | date:'dd MMM yyyy hh:mm a'}}
            </small>
          </div>
          <div>
            <small class="text-muted">
              Created by {{fu.createdBy}} on {{fu.createdAt | date:'dd MMM yyyy hh:mm a'}}
            </small>
          </div>
          <div>
            <small *ngIf="fu.description" [ngClass]="fu.descriptionClass">
              <b>{{fu.description}}</b>
            </small>
          </div>
          <hr *ngIf="!last" class="m-1">
        </div>
      </div>

      <h4 *ngIf="viewHelper.followUp?.loaded && !followUp?.length" class="text-muted">No pending follow up</h4>

      <div *ngIf="viewHelper.followUp?.error">
        Error
      </div>
    </mat-card>
  </div>

  <div class="col-md-4">
    <mat-card>
      <h5 class="mb-1 text-muted">Payment</h5>
      <app-shimmer *ngIf="viewHelper.payment?.loading"></app-shimmer>
      <div *ngIf="viewHelper.payment?.loaded && clientList?.length" class="payment-list pr-1">
        <div *ngFor="let payment of clientList; let last = last;">

          <mde-popover #appPopover="mdePopover" mdePopoverPositionY="above" [mdePopoverOverlapTrigger]="false">
            <mat-card style="max-width: 300px; min-width: 150px;">
              <span class="text-muted">Payment History</span>
              <ng-container *ngIf="payment?.payment?.feePaid?.length; else noPayment">
                <mat-card-content *ngFor="let c of payment?.payment?.feePaid; let last = last">
                  <div class="text-muted d-flex justify-content-between">
                    <div class="text-bold">{{c.amount}}</div>
                    <small matTooltip="Paid On" matTooltipPosition="above" class="text-muted">{{c.datePaid | date :
                      'dd/MM/yy' || 'NA'}}</small>
                  </div>
                  <hr *ngIf="!last">
                </mat-card-content>
              </ng-container>
              <ng-template #noPayment>
                <div>
                  <small class="text-muted mt-1">No payment data</small>
                </div>
              </ng-template>
            </mat-card>
          </mde-popover>

          <div class="d-flex justify-content-between align-items-center">
            <span matTooltip="Go to advance page" matTooltipPosition="above" class="pointer"
              [routerLink]="['/','client','details', payment.clientId]" [queryParams]="{tabIndex : '2'}">
              {{payment.name}}
            </span>
            <small matTooltip="Next expected payment date" matTooltipPosition="above" class="text-muted">
              {{payment.payment.nextDueDate | date:'dd MMM yyyy'}}
            </small>
          </div>
          <div>
            <small class="text-muted" [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="hover"
              #popoverTrigger="mdePopoverTrigger">
              Payment Remaining {{payment.payment.remaining}}
            </small>
            <br>
            <small *ngIf="payment.payment.paymentText" [ngClass]="payment.payment.paymentTextClass">
              <b>{{payment.payment.paymentText}}</b>
            </small>
          </div>
          <hr *ngIf="!last" class="m-1">
        </div>
      </div>

      <h4 *ngIf="viewHelper.payment?.loaded && !clientList?.length" class="text-muted">No pending payments</h4>

      <div *ngIf="viewHelper.payment?.error">
        Error
      </div>
    </mat-card>
  </div>
</div>
<!-- 
<h1 class="text-center">
  We are working on it
</h1> -->