import { Action } from '@ngrx/store';

export const GET_COURSE_LIST_SERVER = 'GET_COURSE_LIST_SERVER';
export const ADD_NEW_COURSE = 'ADD_NEW_COURSE';
export const DELETE_COURSE = 'DELETE_COURSE';
export const COURSE_LIST_SERVER = 'COURSE_LIST_SERVER';
// export const TIME_HOP_COMPLETED = 'Time hop completed';

export class GetListOfCourse implements Action {
  readonly type: string = GET_COURSE_LIST_SERVER;

  constructor(public payLoad: any) {
    console.log(`[ACTION] ${GET_COURSE_LIST_SERVER}`);
  }
}
// TIME_HOP_COMPLETED
export class SuccessCourseComplete implements Action {
  readonly type: string = COURSE_LIST_SERVER;

  constructor(public payLoad: any) {
    console.log(`[ACTION] ${COURSE_LIST_SERVER}`);
  }
}
export class addNewCourse implements Action {
  readonly type: string = ADD_NEW_COURSE;

  constructor(public payLoad: any) {
    console.log(`[ACTION] ${ADD_NEW_COURSE}`);
  }
}

export class deleteCourse implements Action {
  readonly type: string = DELETE_COURSE;

  constructor(public payLoad: any) {
    console.log(`[ACTION] ${DELETE_COURSE}`);
  }
}

// // INITIATE_TIME_HOP
// export class InitiateTimeHop implements Action {
//   readonly type: string = INITIATE_TIME_HOP;

//   constructor(public payLoad: boolean) {
//     console.log(`[ACTION] ${INITIATE_TIME_HOP}`);
//   }
// }

// // TIME_HOP_COMPLETED
// export class TimeHopComplete implements Action {
//   readonly type: string = TIME_HOP_COMPLETED;

//   constructor(public payLoad: any) {
//     console.log(`[ACTION] ${TIME_HOP_COMPLETED}`);
//   }
// }

export type CourseAction = GetListOfCourse | addNewCourse | deleteCourse | SuccessCourseComplete;
