import { Component, OnDestroy, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Observable, interval, Subscription } from 'rxjs';
import { TokenService, SettingService, UserPermissionHandler } from '../../core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PermissionObj } from 'src/app/shared/constants/permission';
import { NotificationModel } from 'src/app/shared/models/notification.model';
import { HttpService } from 'src/app/shared/services/http.service';
import { UrlService } from 'src/app/shared/services/url.service';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminLayoutComponent implements OnDestroy {
  @ViewChild('drawer') drawer: any;
  public selectedItem: string = '';
  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result: BreakpointState) => result.matches));
  public userDataAndPermissions: any = {};
  public notifications: NotificationModel[];
  public notificationHelper = {
    show: false,
    count: null,
    showAll: false
  };
  private sub: Subscription;
  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private token: TokenService,
    private authService: AuthService,
    private settings: SettingService,
    private httpService: HttpService,
  ) {
    this.userDataAndPermissions = this._setPermission();
    this.getNotifications();
    const timeDelayNoti = 60 * 1000 /** one min */
    this.sub = interval(timeDelayNoti)
      .subscribe(() => { this.getNotifications() });
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private _setPermission() {
    const userInfo = this.settings.getUser();
    const userPermissionHandler = new UserPermissionHandler(userInfo);

    const user = userPermissionHandler.hasPermission(PermissionObj.MANAGE_USER);
    const extra = userPermissionHandler.hasPermission(PermissionObj.EXTRA_DATA);
    const company = userPermissionHandler.hasPermission(PermissionObj.COMPANY);
    const country = userPermissionHandler.hasPermission(PermissionObj.COUNTRY);
    const university = userPermissionHandler.hasPermission(PermissionObj.UNIVERSITY);
    const service = userPermissionHandler.hasPermission(PermissionObj.SERVICE_PROVIDED);
    const course = userPermissionHandler.hasPermission(PermissionObj.COURSE);
    const searchCourse = userPermissionHandler.hasPermission(PermissionObj.SEARCH_COURSE);
    const invoice = userPermissionHandler.hasPermission(PermissionObj.INVOICE);
    return {
      userName: userInfo.name,
      userRoles: userInfo.userRole?.join(' ,'),
      user,
      extra,
      company,
      country,
      university,
      service,
      course,
      searchCourse,
      invoice
    }
  }

  closeSideNav() {
    if (this.drawer._mode == 'over') {
      this.drawer.close();
    }
  }

  logout() {
    this.settings.removeUser();
    this.token.clear();
    this.authService.setAuth(false);
    this.router.navigateByUrl('/auth/login')
  }

  getNotifications() {
    const userId = this.authService.getUserId();
    this.httpService.fetchData(`${UrlService.NOTIFICATION_URL}/${userId}?all=${this.notificationHelper.showAll}`)
      .toPromise()
      .then((res: any) => {
        this.notifications = res.data;
        this.notificationHelper.count = this.notifications?.filter(x => !x.isRead)?.length;
      }, () => {
      }).finally(() => { });
  }

  markAsReadEmit(notification: NotificationModel) {
    this.httpService.putData(UrlService.NOTIFICATION_URL, notification)
      .toPromise().finally(
        () => this.getNotifications()
      )
  }

  loadAll(showAll: boolean) {
    this.notificationHelper.showAll = showAll;
    this.getNotifications();
  }

}
