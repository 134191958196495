import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { UserModel } from 'src/app/routes/user/user.model';

@Component({
  selector: 'app-user-name',
  templateUrl: './user-name.component.html',
  styleUrls: ['./user-name.component.scss']
})
export class UserNameComponent implements OnChanges {
  @Input() id: string;
  public user: UserModel;
  constructor(
    private store: Store<AppState>,
  ) {     
  }

  ngOnChanges(): void {
    let userList: UserModel[];
    const courseList$: Subscription = this.store.pipe(take(1)).subscribe(s => userList = s.user.data);
    courseList$.unsubscribe();
    const filteredCourse = userList
      .filter((c: UserModel) => c._id === this.id)
    this.user = filteredCourse?.length ? filteredCourse[0] : {};
  }

}
