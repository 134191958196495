import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ChartConfiguration,
  ChartType,
  ChartEvent,
  CategoryScale,
} from 'chart.js';
import Chart from 'chart.js/auto';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { ClientModel } from 'src/app/shared/models/client.model';
import { HttpService } from 'src/app/shared/services/http.service';
import { UrlService } from 'src/app/shared/services/url.service';
import * as _ from 'lodash';
import { CommonService } from 'src/app/shared/services/common.service';

// ChartJS.register(CategoryScale)

@Component({
  selector: 'app-chart1',
  templateUrl: './chart1.component.html',
  styleUrls: ['./chart1.component.scss']
})
export class Chart1Component implements OnInit {

  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        label: 'Last 30 day trend',
        backgroundColor: 'rgba(148,159,177,0.2)',
        borderColor: 'rgba(148,159,177,1)',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      },
    ],
    labels: []
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.5
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {},
      'y-axis-0':
      {
        position: 'left',
      },
      'y-axis-1': {
        position: 'right',
        // grid: {
        //   color: 'rgba(255,0,0,0.3)',
        // },
        // ticks: {
        //   color: 'red'
        // }
      }
    },

    plugins: {
      legend: { display: true },
      // annotation: {
      //   annotations: [
      //     {
      //       type: 'line',
      //       scaleID: 'x',
      //       value: 'March',
      //       borderColor: 'orange',
      //       borderWidth: 2,
      //       label: {
      //         position: 'center',
      //         enabled: true,
      //         color: 'orange',
      //         content: 'LineAnno',
      //         font: {
      //           weight: 'bold'
      //         }
      //       }
      //     },
      //   ],
      // }
    }
  };

  public viewHelper = {
    loading: false,
    loaded: false,
    error: false,
    noData: false,
    isUpdating: false,
  }

  public chartData;


  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  // events
  public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    // console.log(event, active);
  }

  public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    // console.log(event, active);
  }

  constructor(
    private commonService: CommonService,
    private httpService: HttpService
  ) {
    Chart.register(CategoryScale);
  }

  ngOnInit(): void {

  }
  load(companyIdParam) {

    const filter = {
      createdAt: JSON.stringify({
        startDate: moment().subtract(29, 'days').startOf('day'),
        endDate: moment().endOf('day')
      })
    }
    const urlFilterParms = new URLSearchParams(filter).toString();
    this.viewHelper = { ...this.viewHelper, loading: true, loaded: false, error: false, noData: false };
    this.chartData = [];
    const url = `${UrlService.CLIENT_URL}/dashboard?${urlFilterParms}${companyIdParam ? '&' + companyIdParam : ''}`
    this.httpService.fetchData(url)
      .toPromise()
      .then((res: any) => {
        this._generateChartData(res?.data);
        this.viewHelper.loaded = true;
      })
      .catch(() => this.viewHelper.error = true)
      .finally(() => this.viewHelper.loading = false);
  }

  private _generateChartData(data: ClientModel[]) {
    let label = [], dataSet = [];
    data.forEach((client: ClientModel) => {
      client['name'] = `${client.firstName || ''} ${client.lastName || ''}`;
      client['createdDate'] = moment(client.createdAt).format('DD-MMM-YY')
    });
    this.chartData = _.groupBy(data, 'createdDate');
    Object.keys(this.chartData).forEach(date => {
      label.push(date);
      dataSet.push(this.chartData[date]?.length);
    });
    this.lineChartData.datasets[0].data = dataSet.reverse();
    this.lineChartData.labels = label.reverse();
    this.chart?.update();
    // return {
    //   label, dataSet
    // }
    // console.log(label, dataSet);

  }
}
