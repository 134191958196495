import { Injectable } from '@angular/core';
import { LocalStorageService } from '..';
import { HttpService } from './http.service';
import { UrlService } from './url.service';
import { Observable } from 'rxjs';
import { SettingService } from 'src/app/core';
import { ApplicationConstants } from '../constants/application';
import { CompanyModel } from 'src/app/routes/company/company.model';

const NAMESPACE_COUNTRY = 'COUNTRY';
const NAMESPACE_UNIVERSITY = 'UNIVERSITY';
const NAMESPACE_COURSE = 'COURSE';
const NAMESPACE_SERVICE_PROVIDED = 'SERVICE_PROVIDED';
const NAMESPACE_USER = 'USER';
const NAMESPACE_COMPANY = 'COMPANY';

@Injectable({
  providedIn: 'root'
})
export class LazyDataLoaderService {

  constructor(
    private httpService: HttpService,
    private localStorageService: LocalStorageService,
    private settings: SettingService
  ) { }


  /**
   * getCountryList
   * @param onFullFill on Success
   * @param onError on eror
   * @param force to force load from server
   */
  public getCountryList(force?: boolean): Observable<any> {


    let country: any;
    return new Observable(observer => {

      if (!force)
        country = this.localStorageService.get(NAMESPACE_COUNTRY, true);
      if (!force && country?.data?.length) {
        observer.next(country);
      } else {
        this.httpService.fetchData(UrlService.COUNTRY_URL)
          .subscribe((res: any) => {
            const data = { data: res?.data ? res.data : [], updatedAt: new Date() }
            observer.next(data);
            observer.complete();
            this.localStorageService.set(NAMESPACE_COUNTRY, data)
          }, error => {
            observer.error(error)
            observer.complete();
          });
      }

    });
  }


  /**
   * getUniversityList
   * @param onFullFill on Success
   * @param onError on eror
   * @param force to force load from server
   */
  public getUniversityList(force?: boolean): Observable<any> {
    let course: any;
    return new Observable(observer => {
      if (!force)
        course = this.localStorageService.get(NAMESPACE_UNIVERSITY, true);
      if (!force && course?.data?.length) {
        observer.next(course);
      } else {
        this.httpService.fetchData(UrlService.UNIVERSITY_URL)
          .subscribe((res: any) => {
            const data = { data: res?.data ? res.data : [], updatedAt: new Date() }
            observer.next(data);
            observer.complete();
            this.localStorageService.set(NAMESPACE_UNIVERSITY, data)
          }, error => {
            observer.error(error)
            observer.complete();
          });
      }

    });
  }
  /**
   * getCourseList
   */
  public getCourseList(force?: boolean): Observable<any> {

    let course: any;
    return new Observable(observer => {

      if (!force)
        course = this.localStorageService.get(NAMESPACE_COURSE, true);
      if (!force && course?.data?.length) {
        observer.next(course);
      } else {
        this.httpService.fetchData(UrlService.COURSE_URL)
          .subscribe((res: any) => {
            const data = { data: res?.data ? res.data : [], updatedAt: new Date() }
            observer.next(data);
            observer.complete();
            this.localStorageService.set(NAMESPACE_COURSE, data)
          }, error => {
            observer.error(error)
            observer.complete();
          });
      }

    });
  }


  /**
   * getServiceProvidedList
   */
  public getServiceProvidedList(force?: boolean): Observable<any> {

    let serviceProvided: any;
    return new Observable(observer => {

      if (!force)
        serviceProvided = this.localStorageService.get(NAMESPACE_SERVICE_PROVIDED, true);
      if (!force && serviceProvided?.data?.length) {
        observer.next(serviceProvided);
      } else {
        this.httpService.fetchData(this._setUrlWithCompanyId(UrlService.SERVICE_PROVIDED_URL))
          .subscribe((res: any) => {
            const data = { data: res?.data ? res.data : [], updatedAt: new Date() }
            observer.next(data);
            observer.complete();
            this.localStorageService.set(NAMESPACE_SERVICE_PROVIDED, data)
          }, error => {
            observer.error(error)
            observer.complete();
          });
      }

    });
  }

  /**
  * getServiceProvidedList
  */
  public getUserList(force?: boolean): Observable<any> {

    let serviceProvided: any;
    return new Observable(observer => {

      if (!force)
        serviceProvided = this.localStorageService.get(NAMESPACE_USER, true);
      if (!force && serviceProvided?.data?.length) {
        observer.next(serviceProvided);
      } else {
        this.httpService.fetchData(UrlService.USER_URL)
          .subscribe((res: any) => {
            const data = { data: res?.data ? res.data : [], updatedAt: new Date() }
            observer.next(data);
            observer.complete();
            this.localStorageService.set(NAMESPACE_USER, data)
          }, error => {
            observer.error(error)
            observer.complete();
          });
      }

    });
  }

  async loadCompany(forceLoad?: boolean): Promise<CompanyModel[]> {
    try {
      let res;
      if (!forceLoad) {
        res = this.localStorageService.get(NAMESPACE_COMPANY, true);
      }
      if (!Object.keys(res)?.length || forceLoad) {
        res = await this.httpService.fetchData(UrlService.COMPANY_URL).toPromise();
        this.localStorageService.set(NAMESPACE_COMPANY, { data: res?.data ? res.data : [], updatedAt: new Date() })
      }

      return res?.['data'] ?? [];
    } catch (error) {

    }
  }

  private _setUrlWithCompanyId(url): string {
    const companyId = this.settings.getUser()?.company;
    return companyId === ApplicationConstants.allCompanyId ? url : `${url}?company=${companyId}`
  }
}
